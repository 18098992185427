import Fuse from 'fuse.js'
import {
  cloneDeep,
  debounce,
  differenceBy,
  differenceWith,
  isEqual,
} from 'lodash-es'
import {
  DueDateType,
  TaskLevel,
  TaskFilterCriteria,
  ActivityType,
  DateFieldFilter,
} from '#task/constant'
import { FieldType } from '#field/constant'
import type { BaseTaskType } from '#task-type/types'
import type { Field } from '#field/types'
import type { SortType } from '#board/types'
import type {
  TaskGlobalFilter,
  TaskGlobalFilterCustomField,
  TaskGlobalFilterLabel,
  TaskGlobalFilterMember,
  TaskGlobalFilterModule,
  TaskGlobalFilterStatus,
  TaskGlobalFilterTaskType,
  TaskItemLoader,
} from '#task/types'

const $useTaskFilterContext = () => {
  const defaultFilter = (): TaskGlobalFilter => ({
    [TaskFilterCriteria.SEARCH]: '',
    [TaskFilterCriteria.INCLUDE_MEMBER]: {
      condition: {
        all: false,
        assignedToMe: false,
        no: false,
      },
      members: [],
    },
    [TaskFilterCriteria.EXCLUDE_MEMBER]: {
      members: [],
    },
    [TaskFilterCriteria.DUE_DATE]: [],
    [TaskFilterCriteria.ACTIVITY]: [],
    [TaskFilterCriteria.INCLUDE_LABEL]: {
      condition: {
        all: false,
        no: false,
      },
      labels: [],
    },
    [TaskFilterCriteria.EXCLUDE_LABEL]: {
      labels: [],
    },
    [TaskFilterCriteria.INCLUDE_STATUS]: {
      condition: {
        all: false,
      },
      statuses: [],
    },
    [TaskFilterCriteria.EXCLUDE_STATUS]: {
      statuses: [],
    },
    [TaskFilterCriteria.INCLUDE_MODULE]: {
      condition: {
        all: false,
        no: false,
      },
      tasks: [],
    },
    [TaskFilterCriteria.INCLUDE_TASK_TYPE]: {
      condition: {
        all: false,
      },
      taskTypes: [],
    },
    [TaskFilterCriteria.INCLUDE_FIELD]: {},
    [TaskFilterCriteria.EXCLUDE_FIELD]: {},
  })

  const globalFilter = reactive<TaskGlobalFilter>(defaultFilter())
  const filteredTasksCount = ref(0)
  const isCollapsedTasksNoMatch = ref(true)
  /**
   * Because subtasks are not showed in board views, so we need to store it separately when filter
   */
  const filteredSubtaskIds = ref<Record<string, string[]>>({})

  const filterCriteria = computed(() => {
    return Object.entries(defaultFilter()).reduce((acc, entry) => {
      const [key, value] = entry as [
        keyof TaskGlobalFilter,
        keyof TaskGlobalFilter[keyof TaskGlobalFilter]
      ]
      if (!isEqual(globalFilter[key], value)) {
        acc[key] = globalFilter[key]
      }

      return acc
    }, {} as Record<string, unknown>)
  })

  const filterCriteriaCount = computed(
    () => Object.keys(filterCriteria.value).length
  )

  const resetFilter = () => {
    extend(globalFilter, defaultFilter())
    filteredSubtaskIds.value = {}
  }

  const isHasFilter = computed(() => {
    return filterCriteriaCount.value > 0
  })

  return {
    globalFilter,
    isHasFilter,
    filterCriteria,
    filterCriteriaCount,
    filteredTasksCount,
    filteredSubtaskIds,
    isCollapsedTasksNoMatch,
    defaultFilter,
    resetFilter,
  }
}

export const useTaskFilterContext = createSharedComposable(
  $useTaskFilterContext
)

export const useSyncTaskFilterURL = () => {
  const { globalFilter, isHasFilter, filterCriteria } = useTaskFilterContext()

  const transformCondition = <T extends Record<string, boolean>>(
    criteria: TaskFilterCriteria,
    condition: T
  ) => {
    const transformArr = Object.keys(condition).reduce((acc, key) => {
      if (condition[key]) {
        acc.push([criteria, key])
      }

      return acc
    }, [] as string[][])
    return transformArr
  }

  const transformSelectedValues = (
    criteria: TaskFilterCriteria,
    values: string[]
  ) => {
    const transformArr = values.reduce((acc, value) => {
      acc.push([criteria, value])
      return acc
    }, [] as string[][])
    return transformArr
  }

  const syncFilterToURL = () => {
    const url = new URL(location.href)
    const params = new URLSearchParams(location.search)
    if (!isHasFilter.value) {
      params.delete('filter')
      url.search = params.toString()
      return history.replaceState({}, '', url.toString())
    }

    const normalizeFilter = Object.entries(filterCriteria.value).reduce(
      (acc, entry) => {
        const [key, value] = entry
        if (key === TaskFilterCriteria.SEARCH) {
          acc.push([TaskFilterCriteria.SEARCH, value as string])
        }

        if (key === TaskFilterCriteria.INCLUDE_MEMBER) {
          const interalValue = value as TaskGlobalFilterMember
          acc.push(
            ...transformCondition(
              TaskFilterCriteria.INCLUDE_MEMBER,
              interalValue.condition
            )
          )
          if (!interalValue.condition.all) {
            acc.push(
              ...transformSelectedValues(
                TaskFilterCriteria.INCLUDE_MEMBER,
                interalValue.members
              )
            )
          }
        }

        if (key === TaskFilterCriteria.EXCLUDE_MEMBER) {
          const interalValue = value as TaskGlobalFilterMember
          acc.push(
            ...transformSelectedValues(
              TaskFilterCriteria.EXCLUDE_MEMBER,
              interalValue.members
            )
          )
        }

        if (key === TaskFilterCriteria.DUE_DATE) {
          const interalValue = value as string[]
          acc.push(
            ...transformSelectedValues(
              TaskFilterCriteria.DUE_DATE,
              interalValue
            )
          )
        }

        if (key === TaskFilterCriteria.INCLUDE_LABEL) {
          const interalValue = value as TaskGlobalFilterLabel
          acc.push(
            ...transformCondition(
              TaskFilterCriteria.INCLUDE_LABEL,
              interalValue.condition
            )
          )
          if (!interalValue.condition.all) {
            acc.push(
              ...transformSelectedValues(
                TaskFilterCriteria.INCLUDE_LABEL,
                interalValue.labels
              )
            )
          }
        }

        if (key === TaskFilterCriteria.EXCLUDE_LABEL) {
          const interalValue = value as TaskGlobalFilterLabel
          acc.push(
            ...transformSelectedValues(
              TaskFilterCriteria.EXCLUDE_LABEL,
              interalValue.labels
            )
          )
        }

        if (key === TaskFilterCriteria.INCLUDE_STATUS) {
          const interalValue = value as TaskGlobalFilterStatus
          acc.push(
            ...transformCondition(
              TaskFilterCriteria.INCLUDE_STATUS,
              interalValue.condition
            )
          )
          if (!interalValue.condition.all) {
            acc.push(
              ...transformSelectedValues(
                TaskFilterCriteria.INCLUDE_STATUS,
                interalValue.statuses
              )
            )
          }
        }

        if (key === TaskFilterCriteria.EXCLUDE_STATUS) {
          const interalValue = value as TaskGlobalFilterStatus
          acc.push(
            ...transformSelectedValues(
              TaskFilterCriteria.EXCLUDE_STATUS,
              interalValue.statuses
            )
          )
        }

        if (key === TaskFilterCriteria.INCLUDE_MODULE) {
          const interalValue = value as TaskGlobalFilterModule
          acc.push(
            ...transformCondition(
              TaskFilterCriteria.INCLUDE_MODULE,
              interalValue.condition
            )
          )
          if (!interalValue.condition.all) {
            acc.push(
              ...transformSelectedValues(
                TaskFilterCriteria.INCLUDE_MODULE,
                interalValue.tasks
              )
            )
          }
        }

        if (key === TaskFilterCriteria.INCLUDE_TASK_TYPE) {
          const interalValue = value as TaskGlobalFilterTaskType
          acc.push(
            ...transformCondition(
              TaskFilterCriteria.INCLUDE_TASK_TYPE,
              interalValue.condition
            )
          )
          if (!interalValue.condition.all) {
            acc.push(
              ...transformSelectedValues(
                TaskFilterCriteria.INCLUDE_TASK_TYPE,
                interalValue.taskTypes
              )
            )
          }
        }

        if (key === TaskFilterCriteria.ACTIVITY) {
          const interalValue = value as ActivityType[]
          acc.push(
            ...transformSelectedValues(
              TaskFilterCriteria.ACTIVITY,
              interalValue
            )
          )
        }

        if (key === TaskFilterCriteria.INCLUDE_FIELD) {
          const interalValue = value as Record<
            string,
            TaskGlobalFilterCustomField
          >
          acc.push(
            ...Object.entries(interalValue).reduce((acc, entry) => {
              const [fieldId, field] = entry
              acc.push(
                ...transformCondition(
                  `[${field.type}@${fieldId}]` as TaskFilterCriteria,
                  field.condition
                )
              )
              if (!field.condition.all) {
                acc.push(
                  ...transformSelectedValues(
                    `[${field.type}@${fieldId}]` as TaskFilterCriteria,
                    field.options
                  )
                )
              }

              return acc
            }, [] as string[][])
          )
        }

        if (key === TaskFilterCriteria.EXCLUDE_FIELD) {
          const interalValue = value as Record<
            string,
            TaskGlobalFilterCustomField
          >
          acc.push(
            ...Object.entries(interalValue).reduce((acc, entry) => {
              const [fieldId, field] = entry
              acc.push(
                ...transformSelectedValues(
                  `_[${field.type}@${fieldId}]` as TaskFilterCriteria,
                  field.options
                )
              )

              return acc
            }, [] as string[][])
          )
        }

        return acc
      },
      [] as string[][]
    )
    params.set(
      'filter',
      normalizeFilter.map((entry) => entry.join(':')).join(',')
    )
    url.search = decodeURIComponent(params.toString())
    history.replaceState({}, '', url.toString())
  }

  const syncURLToFilter = () => {
    const params = new URLSearchParams(location.search)
    const filter = params.get('filter')
    if (filter) {
      filter.split(',').forEach((entry) => {
        const [criteria, value] = entry.split(':')
        const isValueID = isID(value)

        if (criteria === TaskFilterCriteria.SEARCH) {
          globalFilter[criteria] = value
        }

        if (criteria === TaskFilterCriteria.INCLUDE_MEMBER) {
          const { condition, members } = globalFilter[criteria]
          if (Object.keys(condition).includes(value)) {
            condition[value as keyof typeof condition] = true
          }

          if (isValueID) {
            members.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.DUE_DATE) {
          if (Object.values(DueDateType).includes(value as DueDateType)) {
            globalFilter[TaskFilterCriteria.DUE_DATE].push(value as DueDateType)
          }
        }

        if (criteria === TaskFilterCriteria.INCLUDE_LABEL) {
          const { condition, labels } = globalFilter[criteria]
          if (Object.keys(condition).includes(value)) {
            condition[value as keyof typeof condition] = true
          }

          if (isValueID) {
            labels.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.EXCLUDE_LABEL) {
          const { labels } = globalFilter[criteria]
          if (isValueID) {
            labels.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.INCLUDE_STATUS) {
          const { condition, statuses } = globalFilter[criteria]
          if (Object.keys(condition).includes(value)) {
            condition[value as keyof typeof condition] = true
          }

          if (isValueID) {
            statuses.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.EXCLUDE_STATUS) {
          const { statuses } = globalFilter[criteria]
          if (isValueID) {
            statuses.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.INCLUDE_MODULE) {
          const { condition, tasks } = globalFilter[criteria]
          if (Object.keys(condition).includes(value)) {
            condition[value as keyof typeof condition] = true
          }

          if (isValueID) {
            tasks.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.INCLUDE_TASK_TYPE) {
          const { condition, taskTypes } = globalFilter[criteria]
          if (Object.keys(condition).includes(value)) {
            condition[value as keyof typeof condition] = true
          }

          if (isValueID) {
            taskTypes.push(value)
          }
        }

        if (criteria === TaskFilterCriteria.ACTIVITY) {
          if (Object.values(ActivityType).includes(value as ActivityType)) {
            globalFilter[TaskFilterCriteria.ACTIVITY].push(
              value as ActivityType
            )
          }
        }

        /**
         * Filter for include custom field
         */
        if (/^\[.*\]$/.test(criteria)) {
          const field = criteria.slice(1, -1)
          if (field.includes('@')) {
            const [fieldType, fieldId] = field.split('@')
            const includeField = globalFilter[TaskFilterCriteria.INCLUDE_FIELD]
            if (!includeField[fieldId]) {
              includeField[fieldId] = {
                id: fieldId,
                type: fieldType as FieldType,
                condition: {
                  all: false,
                },
                options: [],
              }
            }

            const { condition, options } = includeField[fieldId]
            if (Object.keys(condition).includes(value)) {
              condition[value as keyof typeof condition] = true
            }

            if (
              [FieldType.CHECKBOX, FieldType.DROPDOWN].includes(
                fieldType as FieldType
              ) &&
              isValueID
            ) {
              options.push(value)
            }

            if (
              fieldType === FieldType.DATE &&
              Object.values(DateFieldFilter).includes(value as DateFieldFilter)
            ) {
              options.push(value)
            }
          }
        }

        /**
         * Filter for exclude custom field
         */
        if (/^_\[.*\]$/.test(criteria)) {
          const field = criteria.slice(2, -1)
          if (field.includes('@')) {
            const [fieldType, fieldId] = field.split('@')
            const excludeField = globalFilter[TaskFilterCriteria.EXCLUDE_FIELD]
            if (!excludeField[fieldId]) {
              excludeField[fieldId] = {
                id: fieldId,
                type: fieldType as FieldType,
                condition: {
                  all: false,
                },
                options: [],
              }
            }

            const { options } = excludeField[fieldId]
            if (isValueID) {
              options.push(value)
            }
          }
        }
      })
    }
  }

  watchDebounced(
    () => filterCriteria.value,
    () => {
      syncFilterToURL()
    }
  )

  onMounted(async () => {
    await nextTick()
    syncURLToFilter()
  })
}

export const useApplyTaskFilter = (options: {
  tasks: Ref<TaskItemLoader[]>
  ready?: Ref<boolean> | boolean
}) => {
  const { globalFilter, filteredTasksCount, isHasFilter, filteredSubtaskIds } =
    useTaskFilterContext()
  const { sortBy, boardData } = useBoardSharedState()
  const { auth } = storeToRefs(useAuthStore())
  const { origin } = useRequestURL()

  const ready: Ref<boolean> = toRef(options.ready ?? true)
  const isFirstFiltered = ref(false)
  const lastQuery = ref({})

  const isSameDay = (date1: Date, date2: Date) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    )
  }

  const rangeDateBetween = (date1: Date, date2: Date) => {
    const dates = []
    const currentDate = new Date(date1)
    while (currentDate <= date2) {
      dates.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return dates
  }

  const getStartOfNextWeek = (startDay: number = 1): Date => {
    const today = new Date()
    const currentDay = today.getDay()
    const daysUntilNextWeek = (7 - currentDay + startDay) % 7 || 7
    const nextWeekStart = new Date(today)
    nextWeekStart.setDate(today.getDate() + daysUntilNextWeek)

    return nextWeekStart
  }

  const getStartOfNextMonth = (): Date => {
    const today = new Date()
    const nextMonth = today.getMonth() + 1
    const startOfNextMonth = new Date(today.getFullYear(), nextMonth, 1)
    return startOfNextMonth
  }

  const getEndOfNextMonth = (): Date => {
    const today = new Date()
    const nextMonth = today.getMonth() + 1
    const startOfMonthAfterNext = new Date(
      today.getFullYear(),
      nextMonth + 1,
      1
    )
    const endOfNextMonth = new Date(startOfMonthAfterNext.getTime() - 1)
    return endOfNextMonth
  }

  /**
   * Using web worker for better performance and prevent blocking main thread
   */
  const { workerStatus, workerFn, workerTerminate } = useWebWorkerFn(
    (boardTasks, query, define, options) =>
      applyFilterAndSortTasks(
        JSON.parse(boardTasks),
        JSON.parse(query),
        JSON.parse(define),
        JSON.parse(options)
      ),
    {
      localDependencies: [
        applyFilterAndSortTasks,
        isSameDay,
        rangeDateBetween,
        getStartOfNextWeek,
        getStartOfNextMonth,
        getEndOfNextMonth,
      ],
      dependencies: [`${origin}/assets/js/fuse.min.js`],
      timeout: 5000,
    }
  )

  const filteredTasks = ref<TaskItemLoader[]>([])

  const isHasQuery = computed(() => isHasFilter.value || sortBy.field)

  syncRef(
    computed(() => filteredTasks.value.length),
    filteredTasksCount,
    {
      direction: 'ltr',
      transform: {
        ltr: (value) => {
          return value
        },
      },
    }
  )

  syncRef(filteredTasks, filteredSubtaskIds, {
    direction: 'ltr',
    transform: {
      ltr: (tasks: TaskItemLoader[]) => {
        if (!isHasFilter.value) {
          return {}
        }

        return tasks.reduce((acc, task) => {
          if (task.parentId) {
            if (!acc[task.parentId]) {
              acc[task.parentId] = []
            }

            acc[task.parentId].push(task.id)
          }

          return acc
        }, {} as Record<string, string[]>)
      },
    },
  })

  /**
   * Build params for filter, add default params to here
   * NOTE: Dont pass value is a function to worker
   */
  const buildParams = <T extends 'query' | 'define' | 'options'>(
    pick: T,
    mergeParams: Record<string, unknown> = {}
  ) => {
    const boardFields = boardData.value.taskTypes.flatMap(
      (taskType: BaseTaskType & { fields: Field[] }) => {
        if (taskType.fields) {
          return taskType.fields
        }

        return []
      }
    )
    const params = {
      query: {
        filter: globalFilter,
        sort: sortBy,
      },
      define: {
        authId: auth.value.id,
        criteria: TaskFilterCriteria,
        dueDateType: DueDateType,
        fieldType: FieldType,
        taskLevel: TaskLevel,
        activityType: ActivityType,
        dateFieldFilter: DateFieldFilter,
        boardFields,
      },
      options: {
        skipFilter: false,
        skipSort: false,
      },
    }
    return {
      ...params[pick],
      ...mergeParams,
    } as (typeof params)[T]
  }

  function applyFilterAndSortTasks(
    tasks: TaskItemLoader[],
    query: { filter: TaskGlobalFilter; sort: SortType },
    define: {
      authId: string
      criteria: typeof TaskFilterCriteria
      dueDateType: typeof DueDateType
      fieldType: typeof FieldType
      taskLevel: typeof TaskLevel
      activityType: typeof ActivityType
      dateFieldFilter: typeof DateFieldFilter
      boardFields: Field[]
    },
    options?: { skipFilter?: boolean; skipSort?: boolean }
  ) {
    const { filter, sort } = query
    const {
      authId,
      criteria,
      dueDateType,
      fieldType,
      activityType,
      dateFieldFilter,
      boardFields,
    } = define
    let filteredTasks: TaskItemLoader[] = tasks
    if (!filteredTasks.length) {
      return []
    }

    const tasksMap = tasks.reduce((acc, task) => {
      acc[task.id] = task
      return acc
    }, {} as Record<string, TaskItemLoader>)

    const isInWebWorker =
      typeof WorkerGlobalScope !== 'undefined' &&
      self instanceof WorkerGlobalScope

    const handleFilterByKeyword = () => {
      if (filter[criteria.SEARCH]) {
        /**
         * If the function is running in web worker, we need to import Fuse from the importScripts
         */
        const FuseInstance = isInWebWorker ? self.Fuse : Fuse
        const fuse = new FuseInstance(filteredTasks, {
          keys: ['name', 'number'],
          threshold: 0.3,
          shouldSort: false,
        })
        const matchingTerms = fuse.search(filter[criteria.SEARCH])
        filteredTasks = matchingTerms.map((item) => item.item)
      }
    }

    const handleFilterByMember = () => {
      if (
        filter[criteria.INCLUDE_MEMBER].members.length ||
        Object.values(filter[criteria.INCLUDE_MEMBER].condition).some(Boolean)
      ) {
        const { condition, members } = filter[criteria.INCLUDE_MEMBER]
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []
          if (condition.no) {
            scopedConditions.push(!task.assigneeId)
          }

          if (condition.all) {
            scopedConditions.push(!!task.assigneeId)
          }

          if (condition.assignedToMe) {
            scopedConditions.push(task.assigneeId === authId)
          }

          if (members.length) {
            scopedConditions.push(members.includes(task.assigneeId as string))
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }

      if (filter[criteria.EXCLUDE_MEMBER].members.length) {
        const { members } = filter[criteria.EXCLUDE_MEMBER]
        filteredTasks = filteredTasks.filter(
          (task) => !members.includes(task.assigneeId as string)
        )
      }
    }

    const handleFilterByLastActivity = () => {
      if (filter[criteria.ACTIVITY].length) {
        const today = new Date()
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []
          if (filter[criteria.ACTIVITY].includes(activityType.LAST_WEEK)) {
            const lastWeek = new Date(today)
            lastWeek.setDate(today.getDate() - 7)
            scopedConditions.push(
              !!task.lastActivity && new Date(task.lastActivity) > lastWeek
            )
          }

          if (filter[criteria.ACTIVITY].includes(activityType.LAST_TWO_WEEKS)) {
            const lastTwoWeeks = new Date(today)
            lastTwoWeeks.setDate(today.getDate() - 14)
            scopedConditions.push(
              !!task.lastActivity && new Date(task.lastActivity) > lastTwoWeeks
            )
          }

          if (
            filter[criteria.ACTIVITY].includes(activityType.LAST_FOUR_WEEKS)
          ) {
            const lastFourWeeks = new Date(today)
            lastFourWeeks.setDate(today.getDate() - 28)
            scopedConditions.push(
              !!task.lastActivity && new Date(task.lastActivity) > lastFourWeeks
            )
          }

          if (
            filter[criteria.ACTIVITY].includes(
              activityType.WITHOUT_LAST_FOUR_WEEKS
            )
          ) {
            const lastFourWeeks = new Date(today)
            lastFourWeeks.setDate(today.getDate() - 28)
            scopedConditions.push(
              !task.lastActivity || new Date(task.lastActivity) < lastFourWeeks
            )
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }
    }

    const handleFilterByDueDate = () => {
      if (filter[criteria.DUE_DATE].length) {
        const today = new Date()
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []
          if (filter[criteria.DUE_DATE].includes(dueDateType.NO_DATE)) {
            scopedConditions.push(!task.dueDate)
          }

          if (filter[criteria.DUE_DATE].includes(dueDateType.OVERDUE)) {
            scopedConditions.push(
              !!task.dueDate && new Date(task.dueDate) < today
            )
          }

          if (filter[criteria.DUE_DATE].includes(dueDateType.TODAY)) {
            scopedConditions.push(
              !!task.dueDate && isSameDay(new Date(task.dueDate), today)
            )
          }

          if (filter[criteria.DUE_DATE].includes(dueDateType.DUE_NEXT_DAY)) {
            const tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate() + 1)
            scopedConditions.push(
              !!task.dueDate && isSameDay(new Date(task.dueDate), tomorrow)
            )
          }

          if (filter[criteria.DUE_DATE].includes(dueDateType.DUE_NEXT_WEEK)) {
            const nextWeek = getStartOfNextWeek()
            const endNextWeek = new Date(nextWeek)
            endNextWeek.setDate(nextWeek.getDate() + 6)
            const isInNextWeekDates = rangeDateBetween(
              nextWeek,
              endNextWeek
            ).some(
              (date) => task.dueDate && isSameDay(new Date(task.dueDate), date)
            )
            scopedConditions.push(!!task.dueDate && isInNextWeekDates)
          }

          if (filter[criteria.DUE_DATE].includes(dueDateType.DUE_NEXT_MONTH)) {
            const startNextMonth = getStartOfNextMonth()
            const endNextMonth = getEndOfNextMonth()
            const isInNextMonthDates = rangeDateBetween(
              startNextMonth,
              endNextMonth
            ).some(
              (date) => task.dueDate && isSameDay(new Date(task.dueDate), date)
            )
            scopedConditions.push(!!task.dueDate && isInNextMonthDates)
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }
    }

    const handleFilterByLabel = () => {
      if (
        filter[criteria.INCLUDE_LABEL].labels.length ||
        Object.values(filter[criteria.INCLUDE_LABEL].condition).some(Boolean)
      ) {
        const { condition, labels } = filter[criteria.INCLUDE_LABEL]
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []
          if (condition.no) {
            scopedConditions.push(!task.labelIds.length)
          }

          if (condition.all) {
            scopedConditions.push(!!task.labelIds.length)
          }

          if (labels.length) {
            scopedConditions.push(
              labels.some((label) => task.labelIds.includes(label))
            )
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }

      if (filter[criteria.EXCLUDE_LABEL].labels.length) {
        const { labels } = filter[criteria.EXCLUDE_LABEL]
        if (labels.length) {
          filteredTasks = filteredTasks.filter((task) => {
            return !labels.some((label) => task.labelIds.includes(label))
          })
        }
      }
    }

    const handleFilterByStatus = () => {
      if (
        filter[criteria.INCLUDE_STATUS].statuses.length ||
        Object.values(filter[criteria.INCLUDE_STATUS].condition).some(Boolean)
      ) {
        const { condition, statuses } = filter[criteria.INCLUDE_STATUS]
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []

          if (condition.all) {
            scopedConditions.push(!!task.statusId)
          }

          if (statuses.length) {
            scopedConditions.push(statuses.includes(task.statusId))
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }

      if (filter[criteria.EXCLUDE_STATUS].statuses.length) {
        const { statuses } = filter[criteria.EXCLUDE_STATUS]
        if (statuses.length) {
          filteredTasks = filteredTasks.filter(
            (task) => !statuses.includes(task.statusId)
          )
        }
      }
    }

    const handleFilterByModule = () => {
      if (
        filter[criteria.INCLUDE_MODULE].tasks.length ||
        Object.values(filter[criteria.INCLUDE_MODULE].condition).some(Boolean)
      ) {
        const { condition, tasks } = filter[criteria.INCLUDE_MODULE]
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []

          if (condition.no) {
            scopedConditions.push(!task.parentId)
          }

          if (condition.all) {
            scopedConditions.push(!!task.parentId)
          }

          if (tasks.length) {
            scopedConditions.push(tasks.includes(task.parentId as string))
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }
    }

    const handleFilterByTaskType = () => {
      if (
        filter[criteria.INCLUDE_TASK_TYPE].taskTypes.length ||
        Object.values(filter[criteria.INCLUDE_TASK_TYPE].condition).some(
          Boolean
        )
      ) {
        const { condition, taskTypes } = filter[criteria.INCLUDE_TASK_TYPE]
        filteredTasks = filteredTasks.filter((task) => {
          const scopedConditions: boolean[] = []

          if (condition.all) {
            scopedConditions.push(!!task.typeId)
          }

          if (taskTypes.length) {
            scopedConditions.push(taskTypes.includes(task.typeId))
          }

          return !scopedConditions.length || scopedConditions.some(Boolean)
        })
      }
    }

    const handleFiterByCustomField = () => {
      const getScopedConditions = (
        field: TaskGlobalFilterCustomField,
        task: TaskItemLoader
      ) => {
        const scopedConditions: boolean[] = []
        const taskField = task.taskFields.find(
          (customField) => customField.field.id === field.id
        )

        if (field.condition.all) {
          if (taskField?.field.type === fieldType.DATE) {
            scopedConditions.push(!!taskField?.value)
          }

          if (taskField?.field.type === fieldType.DROPDOWN) {
            scopedConditions.push(!!taskField?.value)
          }

          if (taskField?.field.type === fieldType.CHECKBOX) {
            scopedConditions.push(
              !!taskField?.value && JSON.parse(taskField.value).length > 0
            )
          }
        }

        if (field.options.length) {
          if (taskField?.field.type === fieldType.DATE) {
            const isMatchCondition = field.options.some((option) => {
              if (option === dateFieldFilter.TODAY) {
                return isSameDay(new Date(taskField.value), new Date())
              }

              if (option === dateFieldFilter.NEXT_DAY) {
                const tomorrow = new Date()
                tomorrow.setDate(tomorrow.getDate() + 1)
                return isSameDay(new Date(taskField.value), tomorrow)
              }

              if (option === dateFieldFilter.NEXT_WEEK) {
                const nextWeek = getStartOfNextWeek()
                const endNextWeek = new Date(nextWeek)
                endNextWeek.setDate(nextWeek.getDate() + 6)
                return rangeDateBetween(nextWeek, endNextWeek).some((date) =>
                  isSameDay(new Date(taskField.value), date)
                )
              }

              if (option === dateFieldFilter.NEXT_MONTH) {
                const startNextMonth = getStartOfNextMonth()
                const endNextMonth = getEndOfNextMonth()
                return rangeDateBetween(startNextMonth, endNextMonth).some(
                  (date) => isSameDay(new Date(taskField.value), date)
                )
              }

              return false
            })
            scopedConditions.push(isMatchCondition)
          }

          if (taskField?.field.type === fieldType.DROPDOWN) {
            const isContainOption =
              !!taskField.value &&
              field.options.includes(taskField.value as string)
            scopedConditions.push(isContainOption)
          }

          if (taskField?.field.type === fieldType.CHECKBOX) {
            const isContainOption =
              !!taskField.value &&
              field.options.some((option) =>
                JSON.parse(taskField.value).includes(option)
              )
            scopedConditions.push(isContainOption)
          }
        }

        return scopedConditions
      }

      if (Object.keys(filter[criteria.INCLUDE_FIELD]).length) {
        Object.entries(filter[criteria.INCLUDE_FIELD]).forEach(([_, field]) => {
          filteredTasks = filteredTasks.filter((task) => {
            const scopedConditions: boolean[] = [
              !field.options.length && !field.condition.all,
              ...getScopedConditions(field, task),
            ]
            return scopedConditions.some(Boolean)
          })
        })
      }

      if (Object.keys(filter[criteria.EXCLUDE_FIELD]).length) {
        Object.entries(filter[criteria.EXCLUDE_FIELD]).forEach(([_, field]) => {
          filteredTasks = filteredTasks.filter((task) => {
            const scopedConditions: boolean[] = [
              true,
              ...getScopedConditions(field, task).map(
                (condition) => !condition
              ),
            ]

            return scopedConditions.every(Boolean)
          })
        })
      }
    }

    const filterChains = [
      handleFilterByKeyword,
      handleFilterByMember,
      handleFilterByDueDate,
      handleFilterByLabel,
      handleFilterByStatus,
      handleFilterByModule,
      handleFilterByTaskType,
      handleFilterByLastActivity,
      handleFiterByCustomField,
    ]

    if (!options?.skipFilter) {
      filterChains.forEach((applyFilter) => applyFilter())
    }

    /**
     * Handle sorting tasks
     */
    let sortFunction = (a: TaskItemLoader, b: TaskItemLoader) =>
      a.position - b.position
    if (sort.key) {
      const { field, key, direction } = sort
      if (key && !Object.values(fieldType).includes(key as FieldType)) {
        sortFunction = (a: TaskItemLoader, b: TaskItemLoader) => {
          let comparison: number
          const aField = a.taskFields.find((item) => item.field.id === key)!
          const bField = b.taskFields.find((item) => item.field.id === key)!
          const defaultValue =
            boardFields.find((item) => item.id === key)?.default || ''
          const valueA = aField ? aField.value : defaultValue
          const valueB = bField ? bField.value : defaultValue
          if (field === fieldType.NUMBER) {
            comparison = +(aField?.value ?? 0) - +(bField?.value ?? 0)
          } else if (field === fieldType.DROPDOWN) {
            let fieldOptions =
              boardFields.find((item) => item.id === key)?.options || []
            if (typeof fieldOptions === 'string') {
              fieldOptions = JSON.parse(fieldOptions)
            }

            const indexA =
              fieldOptions.findIndex((option) => option.value === valueA) || -1
            const indexB =
              fieldOptions.findIndex((option) => option.value === valueB) || -1
            comparison = indexA - indexB
          } else {
            comparison = valueA.localeCompare(valueB)
          }

          return direction === 'asc' ? comparison : -comparison
        }
      } else if (field === fieldType.STATUS) {
        sortFunction = (a: TaskItemLoader, b: TaskItemLoader) => {
          const statusComparison = (a.status?.type ?? 0) - (b.status?.type ?? 0)
          const posComparison = a.status.position - b.status.position
          const comparison =
            statusComparison !== 0 ? statusComparison : posComparison
          return direction === 'asc' ? comparison : -comparison
        }
      } else if (field === fieldType.ASSIGNEE) {
        sortFunction = (a: TaskItemLoader, b: TaskItemLoader) => {
          const assigneeComparison = (a.assignee?.fullName ?? '').localeCompare(
            b.assignee?.fullName ?? ''
          )
          const posComparison = a.position - b.position
          const comparison =
            assigneeComparison !== 0 ? assigneeComparison : posComparison
          return direction === 'asc' ? comparison : -comparison
        }
      } else if (field === fieldType.TIME_RANGE) {
        /**
         * The comparison is done in the following order:
         * 1. If both tasks have start dates, compare the start dates.
         * 2. If one task has a start date and the other does not, the task with the start date is considered smaller.
         * 3. If both tasks have due dates, compare the due dates.
         * 4. If one task has a due date and the other does not, the task with the due date is considered smaller.
         * 5. If both tasks have neither start nor due dates, or if the start and due dates are equal, compare the task positions.
         */
        sortFunction = (a: TaskItemLoader, b: TaskItemLoader) => {
          const aHasStartDate = !!a.startDate
          const bHasStartDate = !!b.startDate
          const aHasDueDate = !!a.dueDate
          const bHasDueDate = !!b.dueDate

          let comparison = 0
          if (aHasStartDate && bHasStartDate) {
            comparison = a.startDate!.localeCompare(b.startDate!)
          } else if (aHasStartDate) {
            comparison = -1
          } else if (bHasStartDate) {
            comparison = 1
          }

          if (comparison === 0) {
            if (aHasDueDate && bHasDueDate) {
              comparison = a.dueDate!.localeCompare(b.dueDate!)
            } else if (aHasDueDate) {
              comparison = -1
            } else if (bHasDueDate) {
              comparison = 1
            }
          }

          if (comparison === 0) {
            comparison = a.position - b.position
          }

          return direction === 'asc' ? comparison : -comparison
        }
      }
    }

    const pushedTasks = new Set<string>([])
    const parentCache = new Map<string, TaskItemLoader[]>()

    filteredTasks = filteredTasks.reduce((acc, task) => {
      let parentId = task.parentId as string
      const parentsToAdd: TaskItemLoader[] = []

      while (parentId) {
        if (parentCache.has(parentId)) {
          parentsToAdd.push(...parentCache.get(parentId)!)
          break
        }

        const parentTask = tasksMap[parentId]
        if (!parentTask) {
          break
        }

        parentsToAdd.push(parentTask)
        parentId = parentTask.parentId as string
      }

      parentCache.set(task.id, parentsToAdd)

      for (const parent of parentsToAdd) {
        if (!pushedTasks.has(parent.id)) {
          acc.push(parent)
          pushedTasks.add(parent.id)
        }
      }

      if (!pushedTasks.has(task.id)) {
        acc.push(task)
        pushedTasks.add(task.id)
      }

      return acc
    }, [] as TaskItemLoader[])

    if (!options?.skipSort) {
      filteredTasks = filteredTasks.sort(sortFunction)
    }

    return filteredTasks
  }

  const applySortTasks = (tasks: TaskItemLoader[]) => {
    return applyFilterAndSortTasks(
      tasks,
      buildParams('query'),
      buildParams('define'),
      buildParams('options', {
        skipFilter: true,
      })
    )
  }

  watch(
    [globalFilter, sortBy, () => ready.value],
    debounce(async ([filter, sort, isReady]) => {
      if (workerStatus.value !== 'SUCCESS') {
        workerTerminate()
      }

      if (isReady) {
        const query = cloneDeep({ filter, sort })
        const isQueryChanged = !isEqual(lastQuery.value, query)
        lastQuery.value = query

        if (!isQueryChanged) {
          return
        }

        if (!isHasQuery.value) {
          filteredTasks.value = options.tasks.value
          return
        }

        const resolvedTasks = await workerFn(
          JSON.stringify(options.tasks.value),
          JSON.stringify(buildParams('query')),
          JSON.stringify(buildParams('define')),
          JSON.stringify(buildParams('options'))
        )
        isFirstFiltered.value = true
        filteredTasks.value = resolvedTasks || []
      }
    }, 100),
    {
      immediate: true,
    }
  )

  /**
   * Apply filter when tasks change
   */
  watch(
    [() => options.tasks.value, () => ready.value],
    ([tasks, isReady], [oldTasks]) => {
      if (isReady) {
        oldTasks = oldTasks || []
        if (!isHasQuery.value || !tasks.length) {
          filteredTasks.value = tasks
          return
        }

        if (isFirstFiltered.value) {
          if (tasks.length > oldTasks.length) {
            /**
             * If a new task is added, we need ignore it from filter
             */
            const addedTasks = differenceBy(tasks, oldTasks, 'id')
            addedTasks.forEach((task) => {
              if (task.level !== TaskLevel.SUBTASK) {
                filteredTasks.value.push(task)
                filteredTasks.value = applySortTasks(filteredTasks.value)
              }
            })
          } else if (tasks.length === oldTasks.length) {
            /**
             * If a task is updated, we need to update it in the filtered tasks and reapply the filter without filter
             */
            const updatedTasks = differenceWith(tasks, oldTasks, isEqual)
            updatedTasks.forEach((task) => {
              const index = filteredTasks.value.findIndex(
                (filteredTask) => filteredTask.id === task.id
              )
              /**
               * If index is -1, it means the task is not in the filtered tasks, maybe it was created
               */
              if (index === -1) {
                filteredTasks.value.push(task)
                filteredTasks.value = applySortTasks(
                  filteredTasks.value.filter((task) => !isOptimisticId(task.id))
                )
              } else {
                filteredTasks.value[index] = task
                filteredTasks.value = applySortTasks(filteredTasks.value)
              }
            })
          } else {
            /**
             * If a task is deleted, we need to remove it from the filtered tasks
             */
            const deletedTasks = differenceBy(oldTasks, tasks, 'id')
            deletedTasks.forEach((task) => {
              const index = filteredTasks.value.findIndex(
                (filteredTask) => filteredTask.id === task.id
              )
              if (index > -1) {
                filteredTasks.value.splice(index, 1)
              }
            })
          }
        }
      }
    },
    {
      immediate: true,
    }
  )

  return {
    filteredTasksCount,
    filteredTasks,
    applyFilterAndSortTasks,
  }
}
